
export default {
  data() {
    return {
      picker: 0,
    }
  },
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
}
